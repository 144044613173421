import React from 'react';
import Routes from './pages';
import './App.css';

function App() {
  return (
    <div className="App">
      <h2>Citation Viewer</h2>
      <div style={{padding: 4}}>
        <Routes />
      </div>
    </div>
  );
}

export default App;
