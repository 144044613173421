var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};
/**
 * @file Main app configuration
 * @author Harris Lummis
 */


var development = {
  apiGateway: {
    REGION: 'us-west-1',
    URL: 'https://gllhoy7ire.execute-api.us-west-1.amazonaws.com/dev'
  },
  dynamodb: {
    infractions: {
      tableName: 'InfractionsDev',
      region: 'us-west-1',
      getLimit: 200
    }
  }
};
var production = {
  apiGateway: {
    REGION: 'us-west-1',
    URL: 'https://haonmqi1j5.execute-api.us-west-1.amazonaws.com/prod'
  },
  dynamodb: {
    infractions: {
      tableName: 'Infractions',
      region: 'us-west-1',
      getLimit: 200
    }
  }
};
var test = {
  apiGateway: {
    REGION: 'us-west-1',
    URL: 'http://localhost:3001'
  },
  dynamodb: {
    endpoint: 'http://localhost:8000',
    infractions: {
      tableName: 'InfractionsDev',
      region: 'us-west-1',
      getLimit: 100
    }
  }
}; // Load config dependent on app stage

var config = process.env.REACT_APP_STAGE === 'production' ? production : process.env.REACT_APP_STAGE === 'test' ? test : development;
export default __assign({
  s3: {
    REGION: 'us-west-1',
    BUCKET: 'car-images-lp'
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_lH2YglyOS',
    APP_CLIENT_ID: '505191c3amqfv8e1749badgai3',
    IDENTITY_POOL_ID: 'us-west-2:b1fb5afd-2677-4290-b046-c621f6e6359c'
  },
  MAX_ATTACHMENT_SIZE: 5000000,
  clientId: 'b8e9fa73-7598-416b-9b78-c9b5f526f7ec',
  clientTimezone: 'America/Los_Angeles',
  NUM_PLATE_IMAGES: 5,
  MAX_FOOTAGE_TIME_AGO_HOURS: 48,
  MAX_FOOTAGE_LENGTH_MINUTES: 20
}, config);