import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from '@atlas/web/config';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AtlasClient from '@atlas/client';
import Theme from '@atlas/web/Theme';
import MomentUtils from '@date-io/moment';

Amplify.configure({
  API: {
    endpoints: [
      {
        endpoint: config.apiGateway.URL,
        name: 'parking',
        region: config.apiGateway.REGION,
      },
    ],
  },
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.s3.REGION,
  },
});

AtlasClient.init({
  API: Amplify.API,
  apiName: 'parking',
  logger: new Amplify.Logger('atlas-api'),
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={Theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root') as HTMLElement,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
