/**
 * @file The EnforcerInfraction data-loading container
 */
import React from 'react';
import PageLoader from '../../components/PageLoader';
import EnforcerInfractionView from './EnforcerInfractionView';
import { useFetchInfraction, useFetchInfractionImages } from '@atlas/hooks';
/**
 * Data-fetching container for the EnforcerInfractionView container. Fetches
 * infraction, then generates urls for images of the infraction. Displays
 * a PageLoader while resources are loading.
 */

export var EnforcerInfraction = function EnforcerInfraction(_a) {
  var infractionId = _a.infractionId,
      location = _a.location;

  var _b = useFetchInfraction(infractionId),
      infraction = _b.infraction,
      isFetchingInfraction = _b.isFetching;

  var _c = useFetchInfractionImages(infractionId),
      imageUrls = _c.imageUrls,
      isFetchingImages = _c.isFetching;

  if (isFetchingInfraction || isFetchingImages || !infraction) {
    return React.createElement(PageLoader, null);
  }

  return React.createElement(EnforcerInfractionView, {
    infraction: infraction,
    location: location,
    plateImgSrc: imageUrls.plateImgSrc,
    parkImgSrc: imageUrls.parkImgSrc,
    infractionImgSrc: imageUrls.infractionImgSrc
  });
};
export default EnforcerInfraction;