var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { InvalidViolationTypeError } from '../errors';
import url from 'url'; // Node 6 vs 8 discrepancy

import querystring from 'querystring';
import range from 'lodash/range';
import moment from 'moment-timezone';
/**
 * Format a violation type from the database in human readable format
 * @param violationType - the violation type to format
 * @returns the human-readable form of the violation type string
 */

export function formatViolationType(violationType) {
  switch (violationType) {
    case 'MAX_TIME':
      return 'EXCEEDED TIME';

    case 'RED_ZONE':
      return 'RED ZONE';

    case 'METER':
      return 'EXPIRED_METER';

    case 'PERMIT_EXPIRED':
      return 'EXPIRED_PERMIT';

    case 'INVALID_PERMIT':
      return 'INVALID PERMIT';

    default:
      throw new InvalidViolationTypeError(violationType + " is not a valid violation type");
  }
}
/**
 * Get an API path and querystring parameters from a LinkHeader object
 * @param link - the LinkHeader object obtained from the 'Link' header in a
 * response
 * @param relName - the name of the rel to convert to a usable api path
 * @returns the concatenation of the path and querystring parameters of the
 * specified rel, or the empty string if the rel could not be found
 */

export function getAPIPathFromRel(link, relName) {
  var relLinks = link.rel(relName);

  if (relLinks.length === 0) {
    return '';
  }

  var uri = relLinks[0].uri;
  var urlObj = url.parse(uri);
  return urlObj.pathname + urlObj.search;
}
export function getRemainingAPIPathsFromLinks(link, startPage) {
  var relLinks = link.rel('last');

  if (relLinks.length === 0) {
    return [];
  }

  var uri = relLinks[0].uri;
  var urlObj = url.parse(uri, true);
  var lastPageNum = urlObj.query.page;
  return range(startPage, 1 + +lastPageNum).map(function (pageNum) {
    return urlObj.pathname + '?' + querystring.stringify(__assign(__assign({}, urlObj.query), {
      page: pageNum
    }));
  });
}

function twoDigitStringOfNumber(n) {
  return (n < 10 ? '0' : '') + n;
}
/**
 * Format a duration
 * @param duration - the duration to format
 * @returns a string of the format HH:MM:SS
 */


export function formatDuration(duration) {
  var times = [duration.hours(), duration.minutes(), duration.seconds()];
  return times.map(twoDigitStringOfNumber).join(':');
}
/**
 * Format a time as seen in camera timestamps
 * @param time - the time to format expressed as a date or in seconds since the Unix epoch
 * @param timezone - the client timezone to use for formatting
 * @returns the formatted string
 */

export function formatTimeToMatchImageTimestamp(time, timezone) {
  return moment.tz(time, timezone).format('YYYY/MM/DD HH:mm:ss');
}
/**
 * Format a number to a max decimal precision
 * @param n - the number to format
 * @param precision - the integral max decimal precision
 * @returns the formatted number
 */

export function formatWithMaxPrecision(n, precision) {
  return +n.toFixed(3);
}
/**
 * Format a number as a percentage
 * @param p The number to format
 * @param precision The precision at which to display the percentage. Defaults
 * to no decimals
 * @returns A string containing the formatted percentage
 */

export function formatPercentage(p, precision) {
  if (!precision) {
    return Math.round(p * 100) + "%";
  }

  return (p * 100).toFixed(precision) + "%";
}