var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};
/**
 * @file The InfractionInfoTable component
 */


import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { formatViolationType } from '../../libs/formatLib';
import moment from 'moment-timezone';
export var InfractionInfoTable = function InfractionInfoTable(props) {
  var infraction = props.infraction,
      location = props.location,
      tableProps = props.tableProps;
  var rows = [{
    name: 'Type',
    value: infraction.violationType ? formatViolationType(infraction.violationType) : 'N/A'
  }, {
    name: 'Location',
    value: location
  }, {
    name: 'Plate',
    value: function () {
      var plateVal = infraction.plate ? infraction.plate.toUpperCase() : 'N/A';
      var stateVal = infraction.state ? infraction.state.toUpperCase() : 'N/A';

      if (plateVal === 'N/A' && stateVal === 'N/A') {
        return 'N/A';
      }

      return plateVal + " (" + stateVal + ")";
    }()
  }, {
    name: 'Issue Date',
    value: infraction.endTime ? "" + moment(infraction.endTime).format('L HH:mm') : 'N/A'
  }, {
    name: 'Short Link',
    value: infraction.customerLink ? React.createElement("a", {
      href: infraction.customerLink
    }, infraction.customerLink) : 'N/A'
  }];
  return React.createElement(Table, __assign({}, tableProps), React.createElement(TableHead, null, React.createElement(TableRow, null, React.createElement(TableCell, null, "Info"), React.createElement(TableCell, {
    align: "right"
  }))), React.createElement(TableBody, null, rows.map(function (_a) {
    var name = _a.name,
        value = _a.value;
    return React.createElement(TableRow, {
      key: name
    }, React.createElement(TableCell, null, name), React.createElement(TableCell, {
      align: "right"
    }, value));
  })));
};
export default InfractionInfoTable;