/**
 * @file Top-level page routes
 */
import React from 'react';
import { Switch } from 'react-router-dom';
import SingleInfractionRoute from '@atlas/web/pages/citations/SingleInfractionRoute';

export const pages: React.FunctionComponent = () => (
  <Switch>
    <SingleInfractionRoute path={`/:infractionId`} />
  </Switch>
);

export default pages;
