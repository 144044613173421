var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/** An error that will be thrown whenever an invalid violation type is seen */


var InvalidViolationTypeError =
/** @class */
function (_super) {
  __extends(InvalidViolationTypeError, _super);

  function InvalidViolationTypeError(message) {
    var _this = _super.call(this, message) || this;

    Error.captureStackTrace(_this, InvalidViolationTypeError);
    return _this;
  }

  return InvalidViolationTypeError;
}(Error);

export { InvalidViolationTypeError };