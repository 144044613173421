import blue from '@material-ui/core/colors/blue';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
var Theme = createMuiTheme({
  typography: {
    fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif"
  },
  palette: {
    primary: blue
  }
});
export default Theme;