var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};
/**
 * @file A component for indicating that a page is loading
 * @author Harris Lummis
 */


import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
/** Styles applied to the component */

var styles = function styles() {
  return createStyles({
    root: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });
};
/**
 * PageLoader is used to display a large loading indicator inside of a container that assumes
 * the full width and height of its parent. The loading indicator will display in the center of
 * its container, unless specified otherwise through style overrides
 */


export var PageLoader = function PageLoader(_a) {
  var classes = _a.classes,
      progressProps = _a.progressProps;
  return React.createElement("div", {
    "data-testid": "page-loader",
    className: classes.root
  }, React.createElement(CircularProgress, __assign({
    size: "15%"
  }, progressProps)));
};
export default withStyles(styles)(PageLoader);