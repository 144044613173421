var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import React from 'react';
import { EnforcerInfraction } from '../../containers/EnforcerInfraction';
import { Route } from 'react-router-dom';

var SingleInfractionPage = function SingleInfractionPage(_a) {
  var match = _a.match,
      history = _a.history;
  var infractionId = match.params.infractionId; // TODO: get this dynamically

  var location = 'Burns One-Way';
  return React.createElement(EnforcerInfraction, __assign({}, {
    infractionId: infractionId,
    location: location
  }));
};

export var SingleInfractionRoute = function SingleInfractionRoute(props) {
  return React.createElement(Route, __assign({}, props, {
    render: function render(routeProps) {
      return React.createElement(SingleInfractionPage, __assign({}, routeProps));
    }
  }));
};
export default SingleInfractionRoute;