var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};
/**
 * @file The EnforcerInfractionView container
 */


import React from 'react';
import { InfractionInfoTable } from '../../components/InfractionInfoTable';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
var useStyles = makeStyles(function (theme) {
  return {
    img: {
      width: '100%',
      height: 'auto'
    },
    typographyContainer: {
      paddingLeft: theme.spacing(1)
    },
    tableContainer: {},
    tablePaper: {},
    imagesContainer: {},
    imageContainer: {},
    imagePaper: {}
  };
});
/** Component used for viewing an infraction by enforcers */

export var EnforcerInfractionView = function EnforcerInfractionView(props) {
  var width = props.width,
      infraction = props.infraction,
      location = props.location,
      plateImgSrc = props.plateImgSrc,
      parkImgSrc = props.parkImgSrc,
      infractionImgSrc = props.infractionImgSrc;
  var classes = useStyles(props);
  var imageProps = [{
    title: 'Plate',
    src: plateImgSrc,
    alt: 'Plate Image'
  }, {
    title: 'Park',
    src: parkImgSrc,
    alt: 'Park Image'
  }, {
    title: 'Infraction',
    src: infractionImgSrc,
    alt: 'Infraction Image'
  }];
  return React.createElement(Grid, {
    container: true,
    xs: 12,
    spacing: 2,
    direction: "row",
    justify: "center"
  }, React.createElement(Grid, {
    className: classes.tableContainer,
    container: true,
    item: true,
    direction: "row",
    justify: "center",
    xs: 12
  }, React.createElement(Paper, {
    className: classes.tablePaper
  }, React.createElement(InfractionInfoTable, __assign({}, {
    infraction: infraction,
    location: location
  })))), React.createElement(Grid, {
    className: classes.imagesContainer,
    container: true,
    item: true,
    direction: "row",
    justify: "center",
    alignItems: "center",
    xs: 12,
    spacing: 2
  }, imageProps.map(function (_a) {
    var title = _a.title,
        src = _a.src,
        alt = _a.alt;
    return React.createElement(Grid, {
      className: classes.imageContainer,
      item: true,
      container: true,
      xs: 12,
      md: 6,
      lg: 4,
      key: title,
      direction: "row",
      justify: "center",
      alignItems: "center"
    }, React.createElement(Paper, {
      className: classes.imagePaper
    }, React.createElement("div", {
      className: classes.typographyContainer
    }, React.createElement(Typography, {
      variant: isWidthUp('xs', width, false) ? 'h5' : 'h6'
    }, title)), React.createElement("img", {
      className: classes.img,
      src: src,
      alt: alt
    })));
  })));
};
export default withWidth()(EnforcerInfractionView);